define("@ember/enumerable/mutable", ["exports", "@ember/enumerable", "@ember/object/mixin"], function (_exports, _enumerable, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MutableEnumerable = _mixin.default.create(_enumerable.default);
  var _default = MutableEnumerable;
  _exports.default = _default;
});