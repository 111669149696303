define("@ember/routing/auto-location", ["exports", "@ember/-internals/browser-environment", "@ember/-internals/owner", "@ember/object", "@ember/debug", "@ember/routing/lib/location-utils"], function (_exports, _browserEnvironment, _owner, _object, _debug, _locationUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getHashPath = getHashPath;
  _exports.getHistoryPath = getHistoryPath;
  /**
  @module @ember/routing/auto-location
  */
  /**
    AutoLocation will select the best location option based off browser
    support with the priority order: history, hash, none.
  
    Clean pushState paths accessed by hashchange-only browsers will be redirected
    to the hash-equivalent and vice versa so future transitions are consistent.
  
    Keep in mind that since some of your users will use `HistoryLocation`, your
    server must serve the Ember app at all the routes you define.
  
    Browsers that support the `history` API will use `HistoryLocation`, those that
    do not, but still support the `hashchange` event will use `HashLocation`, and
    in the rare case neither is supported will use `NoneLocation`.
  
    Example:
  
    ```app/router.js
    Router.map(function() {
      this.route('posts', function() {
        this.route('new');
      });
    });
  
    Router.reopen({
      location: 'auto'
    });
    ```
  
    This will result in a posts.new url of `/posts/new` for modern browsers that
    support the `history` api or `/#/posts/new` for older ones, like Internet
    Explorer 9 and below.
  
    When a user visits a link to your application, they will be automatically
    upgraded or downgraded to the appropriate `Location` class, with the URL
    transformed accordingly, if needed.
  
    Keep in mind that since some of your users will use `HistoryLocation`, your
    server must serve the Ember app at all the routes you define.
  
    @class AutoLocation
    @static
    @protected
  */
  class AutoLocation extends _object.default {
    constructor() {
      super(...arguments);
      this.implementation = 'auto';
    }
    /**
     Called by the router to instruct the location to do any feature detection
     necessary. In the case of AutoLocation, we detect whether to use history
     or hash concrete implementations.
        @private
    */
    detect() {
      var rootURL = this.rootURL;
      (false && !(rootURL.charAt(rootURL.length - 1) === '/') && (0, _debug.assert)('rootURL must end with a trailing forward slash e.g. "/app/"', rootURL.charAt(rootURL.length - 1) === '/'));
      var implementation = detectImplementation({
        location: this.location,
        history: this.history,
        userAgent: this.userAgent,
        rootURL,
        documentMode: this.documentMode,
        global: this.global
      });
      if (implementation === false) {
        (0, _object.set)(this, 'cancelRouterSetup', true);
        implementation = 'none';
      }
      var owner = (0, _owner.getOwner)(this);
      (false && !(owner) && (0, _debug.assert)('AutoLocation is unexpectedly missing an owner', owner));
      var concrete = owner.lookup(`location:${implementation}`);
      (false && !(concrete !== undefined) && (0, _debug.assert)(`Could not find location '${implementation}'.`, concrete !== undefined));
      (0, _object.set)(concrete, 'rootURL', rootURL);
      (0, _object.set)(this, 'concreteImplementation', concrete);
    }
    willDestroy() {
      var {
        concreteImplementation
      } = this;
      if (concreteImplementation) {
        concreteImplementation.destroy();
      }
    }
  }
  _exports.default = AutoLocation;
  AutoLocation.reopen({
    rootURL: '/',
    initState: delegateToConcreteImplementation('initState'),
    getURL: delegateToConcreteImplementation('getURL'),
    setURL: delegateToConcreteImplementation('setURL'),
    replaceURL: delegateToConcreteImplementation('replaceURL'),
    onUpdateURL: delegateToConcreteImplementation('onUpdateURL'),
    formatURL: delegateToConcreteImplementation('formatURL'),
    location: _browserEnvironment.location,
    history: _browserEnvironment.history,
    global: _browserEnvironment.window,
    userAgent: _browserEnvironment.userAgent,
    cancelRouterSetup: false
  });
  function delegateToConcreteImplementation(methodName) {
    return function () {
      var _a;
      var {
        concreteImplementation
      } = this;
      (false && !(concreteImplementation) && (0, _debug.assert)("AutoLocation's detect() method should be called before calling any other hooks.", concreteImplementation)); // We need this cast because `Parameters` is deferred so that it is not
      // possible for TS to see it will always produce the right type. However,
      // since `AnyFn` has a rest type, it is allowed. See discussion on [this
      // issue](https://github.com/microsoft/TypeScript/issues/47615).
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return (_a = concreteImplementation[methodName]) === null || _a === void 0 ? void 0 : _a.call(concreteImplementation, ...args);
    };
  }
  function detectImplementation(options) {
    var {
      location,
      userAgent,
      history,
      documentMode,
      global,
      rootURL
    } = options;
    var implementation = 'none';
    var cancelRouterSetup = false;
    var currentPath = (0, _locationUtils.getFullPath)(location);
    if ((0, _locationUtils.supportsHistory)(userAgent, history)) {
      var historyPath = getHistoryPath(rootURL, location);
      // If the browser supports history and we have a history path, we can use
      // the history location with no redirects.
      if (currentPath === historyPath) {
        implementation = 'history';
      } else if (currentPath.substring(0, 2) === '/#') {
        history.replaceState({
          path: historyPath
        }, '', historyPath);
        implementation = 'history';
      } else {
        cancelRouterSetup = true;
        (0, _locationUtils.replacePath)(location, historyPath);
      }
    } else if ((0, _locationUtils.supportsHashChange)(documentMode, global)) {
      var hashPath = getHashPath(rootURL, location);
      // Be sure we're using a hashed path, otherwise let's switch over it to so
      // we start off clean and consistent. We'll count an index path with no
      // hash as "good enough" as well.
      if (currentPath === hashPath || currentPath === '/' && hashPath === '/#/') {
        implementation = 'hash';
      } else {
        // Our URL isn't in the expected hash-supported format, so we want to
        // cancel the router setup and replace the URL to start off clean
        cancelRouterSetup = true;
        (0, _locationUtils.replacePath)(location, hashPath);
      }
    }
    if (cancelRouterSetup) {
      return false;
    }
    return implementation;
  }
  /**
    @private
  
    Returns the current path as it should appear for HistoryLocation supported
    browsers. This may very well differ from the real current path (e.g. if it
    starts off as a hashed URL)
  */
  function getHistoryPath(rootURL, location) {
    var path = (0, _locationUtils.getPath)(location);
    var hash = (0, _locationUtils.getHash)(location);
    var query = (0, _locationUtils.getQuery)(location);
    var rootURLIndex = path.indexOf(rootURL);
    var routeHash;
    var hashParts;
    (false && !(rootURLIndex === 0) && (0, _debug.assert)(`Path ${path} does not start with the provided rootURL ${rootURL}`, rootURLIndex === 0)); // By convention, Ember.js routes using HashLocation are required to start
    // with `#/`. Anything else should NOT be considered a route and should
    // be passed straight through, without transformation.
    if (hash.substring(0, 2) === '#/') {
      // There could be extra hash segments after the route
      hashParts = hash.substring(1).split('#');
      // The first one is always the route url
      routeHash = hashParts.shift();
      // If the path already has a trailing slash, remove the one
      // from the hashed route so we don't double up.
      if (path.charAt(path.length - 1) === '/') {
        routeHash = routeHash.substring(1);
      }
      // This is the "expected" final order
      path += routeHash + query;
      if (hashParts.length) {
        path += `#${hashParts.join('#')}`;
      }
    } else {
      path += query + hash;
    }
    return path;
  }
  /**
    @private
  
    Returns the current path as it should appear for HashLocation supported
    browsers. This may very well differ from the real current path.
  
    @method _getHashPath
  */
  function getHashPath(rootURL, location) {
    var path = rootURL;
    var historyPath = getHistoryPath(rootURL, location);
    var routePath = historyPath.substring(rootURL.length);
    if (routePath !== '') {
      if (routePath[0] !== '/') {
        routePath = `/${routePath}`;
      }
      path += `#${routePath}`;
    }
    return path;
  }
});